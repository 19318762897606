<div class="bg-primary">
  <!-- <div class="container">
    <div class="row d-flex justify-content-center bg-primary">
      <div class="col-12 col-lg-6">
        <div #wrapper class="cmp-rating pt-lg-80 pb-lg-80" id="rating">
          <div class="card shadow card-wrapper" data-element="feedback">
            <div class="cmp-rating__card-first">
              <div class="card-header border-0">
                <h2 class="title-medium-2-semi-bold mb-0" data-element="feedback-title">Quanto sono chiare le
                  informazioni su questa pagina?</h2>
              </div>
              <div class="card-body">
                <fieldset class="rating">
                  <legend class="visually-hidden">Valuta da 1 a 5 stelle la pagina</legend>
                  <input type="radio" id="star5a" name="ratingA" value="5">
                  <label class="full rating-star active" for="star5a" data-element="feedback-rate-5">
                    <svg class="icon icon-sm" role="img" aria-labelledby="first-star" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                    <span class="visually-hidden" id="first-star">Valuta 5 stelle su 5</span>
                  </label>
                  <input type="radio" id="star4a" name="ratingA" value="4">
                  <label class="full rating-star active" for="star4a" data-element="feedback-rate-4">
                    <svg class="icon icon-sm" role="img" aria-labelledby="second-star" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                    <span class="visually-hidden" id="second-star">Valuta 4 stelle su 5</span>
                  </label>
                  <input type="radio" id="star3a" name="ratingA" value="3">
                  <label class="full rating-star active" for="star3a" data-element="feedback-rate-3">
                    <svg class="icon icon-sm" role="img" aria-labelledby="third-star" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                    <span class="visually-hidden" id="third-star">Valuta 3 stelle su 5</span>
                  </label>
                  <input type="radio" id="star2a" name="ratingA" value="2">
                  <label class="full rating-star active" for="star2a" data-element="feedback-rate-2">
                    <svg class="icon icon-sm" role="img" aria-labelledby="fourth-star" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                    <span class="visually-hidden" id="fourth-star">Valuta 2 stelle su 5</span>
                  </label>
                  <input type="radio" id="star1a" name="ratingA" value="1">
                  <label class="full rating-star active" for="star1a" data-element="feedback-rate-1">
                    <svg class="icon icon-sm" role="img" aria-labelledby="fifth-star" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                    <span class="visually-hidden" id="fifth-star">Valuta 1 stelle su 5</span>
                  </label>
                </fieldset>
              </div>
            </div>
            <div class="cmp-rating__card-second d-none" data-step="3">
              <div class="card-header border-0 mb-0">
                <h2 class="title-medium-2-bold mb-0" id="rating-feedback">Grazie, il tuo parere ci aiuterà a migliorare
                  il
                  servizio!</h2>
              </div>
            </div>
            <div class="form-rating d-none">
              <div class="d-none" data-step="1">
                <div class="cmp-steps-rating">
                  <fieldset class="fieldset-rating-one d-none" data-element="feedback-rating-positive">
                    <legend class="iscrizioni-header w-100">
                      <h3
                        class="step-title d-flex flex-column flex-lg-row align-items-lg-center justify-content-between drop-shadow">
                        <span class="d-block text-wrap" data-element="feedback-rating-question">
                          Quali sono stati gli aspetti che hai preferito?
                        </span>
                        <span class="step">1/2</span>
                      </h3>
                    </legend>
                    <div class="cmp-steps-rating__body">
                      <div class="cmp-radio-list">
                        <div class="card card-teaser shadow-rating">
                          <div class="card-body">
                            <div class="form-check m-0">
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating1" type="radio" id="radio-1" value="Le indicazioni erano chiare">
                                <label for="radio-1" class="active" data-element="feedback-rating-answer">Le indicazioni
                                  erano chiare</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating1" type="radio" id="radio-2" value="Le indicazioni erano complete">
                                <label for="radio-2" class="active" data-element="feedback-rating-answer">Le indicazioni
                                  erano complete</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating1" type="radio" id="radio-3"
                                  value="Capivo sempre che stavo procedendo correttamente">
                                <label for="radio-3" class="active" data-element="feedback-rating-answer">Capivo sempre
                                  che stavo procedendo correttamente</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating1" type="radio" id="radio-4" value="Non ho avuto problemi tecnici">
                                <label for="radio-4" class="active" data-element="feedback-rating-answer">Non ho avuto
                                  problemi tecnici</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating1" type="radio" id="radio-5" value="Altro-positivo">
                                <label for="radio-4" class="active" data-element="feedback-rating-answer">Altro</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="fieldset-rating-two d-none" data-element="feedback-rating-negative">
                    <legend class="iscrizioni-header w-100">
                      <h3
                        class="step-title d-flex flex-column flex-lg-row flex-wrap align-items-lg-center justify-content-between drop-shadow">
                        <span class="d-block text-wrap" data-element="feedback-rating-question">
                          Dove hai incontrato le maggiori difficoltà?
                        </span>
                        <span class="step">1/2</span>
                      </h3>
                    </legend>
                    <div class="cmp-steps-rating__body">
                      <div class="cmp-radio-list">
                        <div class="card card-teaser shadow-rating">
                          <div class="card-body">
                            <div class="form-check m-0">
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating2" type="radio" id="radio-6">
                                <label for="radio-6" class="active" data-element="feedback-rating-answer"
                                  value="A volte le indicazioni non erano chiare">A volte le
                                  indicazioni non erano chiare</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating2" type="radio" id="radio-7"
                                  value="A volte le indicazioni non erano complete">
                                <label for="radio-7" class="active" data-element="feedback-rating-answer">A volte le
                                  indicazioni non erano complete</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating2" type="radio" id="radio-8"
                                  value="A volte non capivo se stavo procedendo correttamente">
                                <label for="radio-8" class="active" data-element="feedback-rating-answer">A volte non
                                  capivo se stavo procedendo correttamente</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating2" type="radio" id="radio-9" value="Ho avuto problemi tecnici">
                                <label for="radio-9" class="active" data-element="feedback-rating-answer">Ho avuto
                                  problemi tecnici</label>
                              </div>
                              <div class="radio-body border-bottom border-light cmp-radio-list__item">
                                <input name="rating2" type="radio" id="radio-10" value="Altro-negativo">
                                <label for="radio-10" class="active" data-element="feedback-rating-answer">Altro</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="d-none" data-step="2">
                <div class="cmp-steps-rating">
                  <fieldset>
                    <legend class="iscrizioni-header w-100">
                      <h3
                        class="step-title d-flex flex-column flex-lg-row flex-wrap align-items-lg-center justify-content-between drop-shadow">
                        <span class="d-block text-wrap">
                          Vuoi aggiungere altri dettagli?
                        </span>
                        <span class="step">2/2</span>
                      </h3>
                    </legend>
                    <div class="cmp-steps-rating__body">
                      <div class="form-group">
                        <label for="formGroupExampleInputWithHelp" class="">Dettaglio</label>
                        <input type="text" class="form-control" id="formGroupExampleInputWithHelp"
                          aria-describedby="formGroupExampleInputWithHelpDescription" maxlength="200"
                          data-element="feedback-input-text" autocomplete="off">
                        <small id="formGroupExampleInputWithHelpDescription" class="form-text">
                          Inserire massimo 200 caratteri</small>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="d-flex flex-nowrap pt-4 w-100 justify-content-center button-shadow">
                <button class="btn btn-outline-primary fw-bold me-4 btn-back" type="button">Indietro</button>
                <button class="btn btn-primary fw-bold btn-next" type="submit" form="rating">Avanti</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container">
    <div class="row d-flex justify-content-center p-contacts">
      <div class="col-12 col-lg-6">
        <div class="cmp-contacts">
          <div class="card w-100">
            <div class="card-body">
              <h2 class="title-medium-2-semi-bold">Contatta il Comune</h2>
              <ul class="contact-list p-0">
                <li>
                  <a class="list-item" href="/domande-frequenti">
                    <svg
                      class="icon icon-primary icon-sm"
                      aria-hidden="true"
                      aria-label="Domande frequenti"
                    >
                      <use
                        href="bootstrap-italia/dist/svg/sprites.svg#it-help-circle"
                      ></use></svg
                    ><span>Leggi le domande frequenti</span></a
                  >
                </li>

                <li>
                  <a
                    class="list-item"
                    href="https://myarea.comune.padova.it/myportal/C_G224_RV/servizi/richiesta-assistenza"
                    data-element="contacts"
                  >
                    <svg
                      class="icon icon-primary icon-sm"
                      aria-hidden="true"
                      aria-label="Richiesta assistenza"
                    >
                      <use
                        href="bootstrap-italia/dist/svg/sprites.svg#it-mail"
                      ></use></svg
                    ><span>Richiedi assistenza</span></a
                  >
                </li>

                <li>
                  <a
                    class="list-item"
                    href="/urp-ufficio-le-relazioni-con-il-pubblico"
                  >
                    <svg
                      class="icon icon-primary icon-sm"
                      aria-hidden="true"
                      aria-label="Ufficio per le Relazioni con il Pubblico - URP"
                    >
                      <use
                        href="bootstrap-italia/dist/svg/sprites.svg#it-comment"
                      ></use></svg
                    ><span
                      >Ufficio per le Relazioni con il Pubblico - URP</span
                    ></a
                  >
                </li>

                <li>
                  <a
                    class="list-item"
                    href="https://cup.comune.padova.it"
                    data-element="appointment-booking"
                  >
                    <svg
                      class="icon icon-primary icon-sm"
                      aria-hidden="true"
                      aria-label="Prenota appuntamento"
                    >
                      <use
                        href="bootstrap-italia/dist/svg/sprites.svg#it-calendar"
                      ></use></svg
                    ><span>Prenota appuntamento</span></a
                  >
                </li>
              </ul>

              <h2 class="title-medium-2-semi-bold mt-4">Problemi in città</h2>
              <ul class="contact-list p-0">
                <li>
                  <a class="list-item" href="https://padovapartecipa.it">
                    <svg
                      class="icon icon-primary icon-sm"
                      aria-hidden="true"
                      aria-label="Segnalazione disservizio"
                    >
                      <use
                        href="bootstrap-italia/dist/svg/sprites.svg#it-map-marker-circle"
                      ></use></svg
                    ><span>Segnala disservizio </span></a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="bg-grey-card shadow-contacts">
  <div class="container">
    <div class="row d-flex justify-content-center p-contacts">
      <div class="col-12 col-lg-6">
        <div class="cmp-contacts">
          <div class="card w-100">
            <div class="card-body">
              <h2 class="title-medium-2-semi-bold">Contatta il comune</h2>
              <ul class="contact-list p-0">
                <li><a class="list-item" href="/domande-frequenti">
                    <svg class="icon icon-primary icon-sm" aria-hidden="true" aria-label="Domande frequenti">
                      <use href="bootstrap-italia/dist/svg/sprites.svg#it-help-circle"></use>
                    </svg><span>Leggi le domande frequenti</span></a></li>

                <li><a class="list-item" href="https://myarea.comune.padova.it/myportal/C_G224_RV/servizi/richiesta-assistenza" data-element="contacts">
                    <svg class="icon icon-primary icon-sm" aria-hidden="true" aria-label="Richiesta assistenza">
                      <use href="bootstrap-italia/dist/svg/sprites.svg#it-mail"></use>
                    </svg><span>Richiedi assistenza</span></a></li>

                <li><a class="list-item" href="/urp-ufficio-le-relazioni-con-il-pubblico">
                    <svg class="icon icon-primary icon-sm" aria-hidden="true" aria-label="Ufficio per le Relazioni con il Pubblico - URP">
                      <use href="bootstrap-italia/dist/svg/sprites.svg#it-hearing"></use>
                    </svg><span>Ufficio per le Relazioni con il Pubblico - URP</span></a></li>

                <li><a class="list-item" href="https://cup.comune.padova.it" data-element="appointment-booking">
                    <svg class="icon icon-primary icon-sm" aria-hidden="true" aria-label="Prenota appuntamento">
                      <use href="bootstrap-italia/dist/svg/sprites.svg#it-calendar"></use>
                    </svg><span>Prenota appuntamento</span></a></li>
              </ul>

              <h2 class="title-medium-2-semi-bold mt-4">Problemi in città</h2>
              <ul class="contact-list p-0">
                <li><a class="list-item" href="https://padovapartecipa.it">
                    <svg class="icon icon-primary icon-sm" aria-hidden="true" aria-label="Segnalazione disservizio">
                      <use href="bootstrap-italia/dist/svg/sprites.svg#it-map-marker-circle"></use>
                    </svg><span>Segnala disservizio </span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->